@import url("normalize.8bbf22bd.css");
body {
  background: #ffffff;
  color: #222;
  transition: 0.25s ease all;
}
body.dark {
  background: #222;
  color: #ffffff;
}

/*# sourceMappingURL=index.03a3b867.css.map */
